.discovery_card {
  display: grid;
  grid-template-areas:
    'top'
    'media'
    'main';
}

.discovery_card:not(.horizontal) {
  grid-template-rows: min-content min-content auto;
}

.discovery_card.stacked {
  display: flex;
  flex-direction: column;
}

.discovery_card.no_media.clickable {
  grid-template-areas: 'main';
}

.discovery_card *[slot='top'] {
  grid-area: top;
}
.discovery_card *[slot='main'] {
  grid-area: main;
}
.discovery_card *[slot='media'] {
  grid-area: media;
}

.discovery_card.horizontal *[slot='main'] {
  padding: 2rem;
}
.discovery_card.stacked *[slot='main'] {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
}
.discovery_card.media *[slot='main'] {
  padding: 1.5rem;
}
.discovery_card.image *[slot='main'] {
  padding: 1.5rem;
}
.discovery_card.inline *[slot='main'] {
  margin-top: 1.5rem;
}
.discovery_card.clickable.reversed {
  grid-template-areas:
    'main'
    'media';
}

.discovery_card.no_media.clickable *[slot='main'] {
  padding: var(--v-space-16);
}
.discovery_card.no_media.clickable *[slot='main'] h2 {
  font-size: var(--v-font-16-size);
}
.discovery_card.no_media:not(.clickable) *[slot='main'] {
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}

@media (--v-from-md) {
  .discovery_card.horizontal {
    display: grid !important;
    grid-template-areas: 'media main';
    grid-template-columns: 1fr 1fr;
  }
  .discovery_card.horizontal.reversed {
    grid-template-areas: 'main media';
  }

  .discovery_card.horizontal *[slot='main'] {
    align-self: center;
    padding: 2rem;
  }
}

.mediagridcontainer {
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

@media (--v-from-lg) {
  .discovery_card.stacked *[slot='main'] {
    padding: 3rem;
  }
  .discovery_card.horizontal *[slot='main'] {
    align-self: center;
    padding: 4rem;
  }
  .discovery_card.no_media.clickable *[slot='main'] {
    grid-template-areas: 'main';
    padding: var(--v-space-24);
  }
  .discovery_card.no_media.clickable *[slot='main'] h2 {
    font-size: var(--v-font-20-size);
  }
  .mediagridcontainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .mediabox:nth-child(1),
  .mediabox:nth-child(2),
  .mediabox:nth-child(3) {
    grid-column: auto;
  }
}

@media (--v-from-md) and (--v-until-lg) {
  .mediagridcontainer {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
  }
  .mediabox:nth-child(1) {
    grid-column: 1 / 3;
  }
}
